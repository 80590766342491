import * as React from "react";
import styled from "styled-components";

export const StyledTitles = styled.img`
  width: 100px;
  width: -webkit-fill-available;
  position: relative;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

export default function LazyAndPreloadImage(props) {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    // preload しておく
    const img = new Image();
    img.src = props.src;
    img.crossOrigin = "Anonymous";

    // 読み込めたら img タグ描画する
    img.onload = () => {
      setLoaded(true);
    };
  }, []);

  if (loaded) {
    return (
      <StyledTitles
        src={props.src}
        alt={props.alt}
        width={"100%"}
        // style={{
        //   filter: props.selected === true ? "none" : "grayscale(1)",
        // }}
      ></StyledTitles>
    );
  }

  return (
    <StyledTitles
      src={"/config/images/logo.png"}
      alt={props.alt}
      width={"100%"}
    />
  );
}
