import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import { fetchDataBase } from "./redux/database/databaseActions";
import { fetchDataStake } from "./redux/datastake/datastakeActions";
import LazyAndPreloadImage from "./components/LazyAndPreloadImage";

import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Footer from "./components/Footer";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";
import axios from "axios";

export const MintTitle = styled.p`
  color: #000;
  font-size: 4rem;
  font: bold 1em "futura-pt-bold", serif;
  font-weight: 500;
  line-height: 1.6;
`;
export const StyledInput = styled.input`
  color: #333;
  font-size: 2rem;
  border-radius: 10px;
  padding: 0.1rem 0.1rem;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const StyledTitlesName = styled.p`
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: #ffffff;
  line-height: 2;
`;

export const StyledButton = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  color: #000;
  font-size: 1.4rem;
  font-weight: 800;
  border-radius: 10px;
  padding: 0.6rem 4rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(181, 255, 20, 0.9);
  ${({ bgImage }) =>
    bgImage &&
    `
    color: rgba(0, 0, 0, 0);
    background-image: url(${bgImage});
    background-size: cover;
    background-position:center;
  `}

  :active {
    padding: 0.3rem 2rem;
  }
  :hover {
    ${({ bgImage }) =>
      bgImage &&
      `
    color: rgba(0, 0, 0, 0);
  `}
    opacity: 0.8;
  }
`;
export const StyledButtonWall = styled.button`
  color: #000;
  font-size: 1rem;
  font: bold 1em "futura-pt-bold", serif;
  width: 98%;
  border-radius: 10px;
  padding: 1rem;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: #fff;
  background-size: 0.2rem;
  font-size: 1rem;
  font-weight: 900;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
  /* animation: gradation 1.7s ease-in infinite; */
  :hover {
    cursor: pointer;
  }
`;

export const StyledButtonLoading = styled.button`
  color: #fff;
  font: bold 1em "futura-pt-bold", serif;
  width: 100%;
  border: none;
  border-radius: 0;
  padding: 1rem;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  background-size: 0.2rem;
  font-size: 1rem;
  font-weight: 900;
  background-size: 700% 700%;
  :hover {
    cursor: pointer;
  }
`;

export const StyledButtonTab = styled.button`
  color: #fff;
  font: bold 1em "futura-pt-bold", serif;
  width: 100%;
  max-width: 300px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 1rem;
  margin: 0 1rem 1rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  background-size: 0.2rem;
  font-size: 1rem;
  font-weight: 900;
  background-size: 700% 700%;
  :hover {
    cursor: pointer;
  }
`;

export const BoxList = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0;
  /* border-radius: 24px; */
  /* border: 4px solid #333; */
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const TitlesTitle = styled.p`
  color: var(--accent-text);
  font-size: 30px;
  font: bold 1em "futura-pt-bold", serif;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledTitlesCover = styled.img`
  width: 100%;
  position: absolute;
  /* background-color: rgba(0, 0, 0, 0.6); */
  top: 0;
  left: 0;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: column;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function Home() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const datasub = useSelector((state) => state.datasub);
  const database = useSelector((state) => state.database);
  const datastake = useSelector((state) => state.datastake);
  const [forceUnstakeNum, setforceUnstakeNum] = useState("");
  const [checkAddress, setCheckAddress] = useState("");

  const [data, setData] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const [dispFrame, setDispFrame] = useState(0);
  const [claimingNft, setClaimingNft] = useState(false);
  const [loadingLevel, setLoadingLevel] = useState([]);
  const [feedback, setFeedback] = useState(``);
  const [lang, setlang] = useState(false);
  const [canLvUp, setCanLvUp] = useState(false);
  const [pointRequire, setPointRequire] = useState(false);
  const [stakeSelect, setStakeSelect] = useState([]);
  const [statusSelect, setStatusSelect] = useState(false);
  const [stakingTime, setStakingTime] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [levels, setLevels] = useState([]);
  const [levelsSt, setLevelsSt] = useState([]);
  const [levelsAcc, setLevelsAcc] = useState([]);
  const [unstakeSelect, setUnStakeSelect] = useState([]);
  const [langData, setlangData] = useState({
    fail: "Failed,Please try again.",
    chkops: "you minted NSK! check on your Opensea.",
    minting: "Minting NSK....",
    salestartwl: "Presale 1st Stage：11/26(Sat) 20:00〜(JST)",
    salestartwl2: "Presale 2nd Stage：11/26(Sat) 21:00〜(JST)",
    salestartpb: "Public Sale：11/26(Sat) 22:00〜(JST)",
    soldout: "Sold Out! thank you!",
    wl: "Presale",
    pb: "Public Sale",
    gasfee: "+gas Fee",
    network: "to get NSK,Please Connect to Ethereum Mainnet.",
    busy: "Busy",
    minted: "minted",
    youhavewl: "You are on the Whitelist!",
    youhavenotwl: "You are not Whitelisted.",
    mintwl: "mintWL",
    mintps: "mintPS",
    reload: "reload",
    hold: "hold",
    instaking: "In Staking",
    status: "status",
    selectall: "select all",
    stake: "stake",
    unstake: "unstake",
    selectgod: "select god to stake",
    levelup: "level UP!",
  });

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1650,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_COSTWL: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  // APIリクエストを送る関数
  const fetchData = async () => {
    setLoading(true);
    try {
      // POSTリクエストのためのパラメータ
      const params = new URLSearchParams();
      params.append("add", blockchain.account);
      if (nextToken) {
        params.append("next", nextToken);
      }

      // POSTリクエストの送信
      const response = await axios.post(
        "https://startlands.io/w3p/gh.php",
        params
      );

      // 受け取ったデータを処理
      if (response.data.success && response.data.data.data != null) {
        setData(response.data.data.data); // データの更新
        setNextToken(response.data.next); // 次のトークンの更新
      } else {
        // エラー処理
        setFeedback(
          "Sorry, we are unable to retrieve your list of NFTs due to some trouble. Please check back later."
        );
      }
    } catch (error) {
      // 通信エラーの処理
      setFeedback(
        "Sorry, we are unable to retrieve your list of NFTs due to some trouble. Please check back later."
      );
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  // APIリクエストを送る関数
  const fetchDataByAddess = async () => {
    setLoading(true);
    try {
      // POSTリクエストのためのパラメータ
      const params = new URLSearchParams();
      params.append("add", checkAddress);
      if (nextToken) {
        params.append("next", nextToken);
      }

      // POSTリクエストの送信
      const response = await axios.post(
        "https://startlands.io/w3p/gh.php",
        params
      );

      // 受け取ったデータを処理
      if (response.data.success && response.data.data.data.length > 0) {
        setData(response.data.data.data); // データの更新
        setNextToken(response.data.next); // 次のトークンの更新
      } else {
        // エラー処理
        setFeedback(
          "Sorry, we are unable to retrieve your list of NFTs due to some trouble. Please check back later."
        );
      }
    } catch (error) {
      // 通信エラーの処理
      setFeedback(
        "Sorry, we are unable to retrieve your list of NFTs due to some trouble. Please check back later."
      );
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  // APIリクエストを送る関数
  const fetchList = async (_tokenId) => {
    try {
      const response = await axios.get(
        `https://api.opensea.io/api/v2/orders/ethereum/seaport/listings`,
        {
          params: {
            asset_contract_address:
              "0xb058279f96db97C491f3d3B3673702D2D5497558",
            token_ids: _tokenId,
          },
          headers: {
            accept: "application/json",
            "x-api-key": "285fe619991145c681cde8a7f16719c1",
          },
        }
      );
      // console.log("getList::::::::::::::::::::::::::::");
      // console.dir(response.data.orders.length);
      // console.dir(response.data.orders);
      if (Number(response.data.orders.length) > 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setFeedback(
        "Sorry, we are unable to retrieve your list of NFTs due to some trouble. Please check back later."
      );
      console.error("API Error:", error);
      return true;
    }
  };

  const changeforceUnstakeNum = (event) => {
    const newVal = event.target.value;
    if (forceUnstakeNum != event.target.value) {
      setforceUnstakeNum(newVal);
      setforceUnstakeNum((newVal) => {
        //初回クリック時のpre_countは1
        console.dir(`pass:${newVal}`);
        return newVal;
      });
    }
  };
  const changeCheckAddress = (event) => {
    const newVal = event.target.value;
    if (forceUnstakeNum != event.target.value) {
      setCheckAddress(newVal);
      setCheckAddress((newVal) => {
        //初回クリック時のpre_countは1
        console.dir(`pass:${newVal}`);
        return newVal;
      });
    }
  };

  const forceUnstake = async () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(0);
    let totalGasLimit = String(gasLimit * 1); //個数を1に固定0714(ふりっきー)
    let method;
    let gas;
    try {
      method = await blockchain.stakeSmartContract.methods.unstakeForce([
        forceUnstakeNum,
      ]);
      gas = await method.estimateGas({
        from: blockchain.account,
        value: totalCostWei,
      });

      // ここで他の処理を行う
    } catch (error) {
      // エラーメッセージから特定の部分を抽出
      let errorMessage = error.message;
      console.log(error);
      // 'execution reverted:' という文字列が含まれている場合、その部分だけを抽出
      let startIndex = errorMessage.indexOf("Token not staked");
      if (startIndex !== -1) {
        alert("そのNFTはステーキングされていません。");
      } else {
        // 期待する形式のメッセージがない場合は、全体を表示
        alert(errorMessage);
      }
      return;
    }

    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.1);
    console.log("gasPrice: " + gasPrice);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME}${langData.minting}`);
    setClaimingNft(true);
    blockchain.stakeSmartContract.methods
      .unstakeForce([forceUnstakeNum])
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にアンステークされました。ご確認ください。`
        );
        refreshNFTData(forceUnstakeNum);
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const staking = async () => {
    if (stakeSelect.length == 0) {
      alert("何か選択してください。");
      return;
    }
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(0);
    let totalGasLimit = String(gasLimit * 1); //個数を1に固定0714(ふりっきー)
    let method = await blockchain.stakeSmartContract.methods.stake(stakeSelect);
    let gas = await method.estimateGas({
      from: blockchain.account,
      value: totalCostWei,
    });
    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.1);
    console.log("gasPrice: " + gasPrice);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME}${langData.minting}`);
    setClaimingNft(true);
    blockchain.stakeSmartContract.methods
      .stake(stakeSelect)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas + 50000,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(langData.fail);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(langData.chkops);

        setClaimingNft(false);
        //
      });
  };

  const unstaking = async () => {
    if (stakeSelect.length == 0) {
      alert("何か選択してください。");
      return;
    }
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(0);
    let totalGasLimit = String(gasLimit * 1); //個数を1に固定0714(ふりっきー)
    let method = await blockchain.stakeSmartContract.methods.unstake(
      unstakeSelect
    );
    let gas = await method.estimateGas({
      from: blockchain.account,
      value: totalCostWei,
    });
    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.1);
    console.log("gasPrice: " + gasPrice);

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME}${langData.minting}`);
    setClaimingNft(true);
    blockchain.stakeSmartContract.methods
      .unstake(unstakeSelect)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas + 50000,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(langData.fail);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(langData.chkops);

        setClaimingNft(false);
        //
      });
  };

  // APIリクエストを送る関数
  const refreshNFTData = async (_tokenId) => {
    try {
      const response = await axios.get("https://startlands.io/w3p/mr.php", {
        params: {
          tokenID: _tokenId,
        },
      });
      console.log(response);
    } catch (error) {
      console.error("API Error:", error);
      return true;
    }
  };

  const stakingSole = async (_tokenId) => {
    if (await fetchList(_tokenId)) {
      alert("can't list and stake.");
      return false;
    }

    let loadingArray = loadingLevel;
    loadingArray[_tokenId] = true;
    setLoadingLevel(loadingArray);
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(0);
    let totalGasLimit = String(gasLimit * 1); //個数を1に固定0714(ふりっきー)
    let method = await blockchain.stakeSmartContract.methods.stake([_tokenId]);
    let gas = await method.estimateGas({
      from: blockchain.account,
      value: totalCostWei,
    });
    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.1);
    console.log("gasPrice: " + gasPrice);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`STAKING #${_tokenId}...`);
    setClaimingNft(true);
    blockchain.stakeSmartContract.methods
      .stake([_tokenId])
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas + 50000,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(langData.fail);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`STAKED:#${_tokenId}`);
        getLevel(_tokenId);

        setClaimingNft(false);
        refreshNFTData(_tokenId);
        //
      });
  };

  const unstakingSolo = async (_tokenId) => {
    let loadingArray = loadingLevel;
    loadingArray[_tokenId] = true;
    setLoadingLevel(loadingArray);
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(0);
    let totalGasLimit = String(gasLimit * 1); //個数を1に固定0714(ふりっきー)
    let method = await blockchain.stakeSmartContract.methods.unstake([
      _tokenId,
    ]);
    let gas = await method.estimateGas({
      from: blockchain.account,
      value: totalCostWei,
    });
    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.1);
    console.log("gasPrice: " + gasPrice);

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`UNSTAKING #${_tokenId}...`);
    setClaimingNft(true);
    blockchain.stakeSmartContract.methods
      .unstake([_tokenId])
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(langData.fail);
        getLevel(_tokenId);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`UNSTAKED:#${_tokenId}`);
        getLevel(_tokenId);
        setClaimingNft(false);
        refreshNFTData(_tokenId);

        //
      });
  };

  const getLevel = (_tokenId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.stakeSmartContract !== null
    ) {
      let loadingArray = loadingLevel;
      loadingArray[_tokenId] = true;
      setLoadingLevel(loadingArray);
      setFeedback("checking Points...");
      blockchain.stakeSmartContract.methods
        .calculateUnstakePoints(_tokenId)
        .call()
        .then((receipt) => {
          // console.log(receipt);
          let levelArray = levelsSt;
          levelArray[_tokenId] = receipt;
          setLevelsSt(levelArray);

          levelArray = levels;
          levelArray[_tokenId] = 1;
          setLevels(levelArray);

          let loadingArray = loadingLevel;
          loadingArray[_tokenId] = false;
          setLoadingLevel(loadingArray);
          setFeedback("check your earned points.");
        })
        .catch((error) => {
          let levelArray = levels;
          levelArray[_tokenId] = 0;
          setLevels(levelArray);
          let loadingArray = loadingLevel;
          loadingArray[_tokenId] = false;
          setLoadingLevel(loadingArray);
        });

      blockchain.statusSmartContract.methods
        .getPoint(_tokenId, 0)
        .call()
        .then((receipt) => {
          // console.log(receipt);
          let levelArray = levelsAcc;
          levelArray[_tokenId] = receipt;
          setLevelsAcc(levelArray);
          setFeedback("check your points.");

          let loadingArray = loadingLevel;
          loadingArray[_tokenId] = false;
          setLoadingLevel(loadingArray);
        });
    }
  };
  const setSelectStatus = async (tokenId) => {
    if (blockchain.account !== "" && blockchain.statusSmartContract !== null) {
      setStakingTime(0);
      await blockchain.statusSmartContract.methods
        .getStatuses(tokenId)
        .call()
        .then((receipt) => {
          setStatuses(receipt);
          setStatusSelect(tokenId);
        });
      await blockchain.stakeSmartContract.methods
        .stakingTime(tokenId)
        .call()
        .then((receipt) => {
          setStakingTime(receipt);
        });
    }
  };
  function formatAddress(address) {
    if (!address || address.length < 10) {
      return address;
    }
    return `${address.substring(0, 5)}...${address.substring(
      address.length - 5
    )}`;
  }

  const selectAllStakes = () => {
    let picked = data?.map((item) => item.identifier) || [];
    picked = picked.filter((item) => item != null);
    const uniquePicked = [...new Set(picked)];
    if (stakeSelect.length == uniquePicked.length) {
      setStakeSelect([]);
    } else {
      setStakeSelect(uniquePicked);
    }
  };

  const selectAllUnStakes = () => {
    let picked = data?.map((item) => item.identifier) || [];
    picked = picked.filter((item) => item != null);
    const uniquePicked = [...new Set(picked)];
    if (stakeSelect.length == uniquePicked.length) {
      setUnStakeSelect([]);
    } else {
      setUnStakeSelect(uniquePicked);
    }
  };

  const setSelectStake = (stakeId) => {
    let stakeArray = stakeSelect;
    if (stakeArray.includes(stakeId)) {
      stakeArray.splice(stakeArray.indexOf(stakeId), 1);
    } else {
      stakeArray.push(stakeId);
    }
    setStakeSelect(stakeArray);
  };

  const setSelectUnStake = (stakeId) => {
    let stakeArray = unstakeSelect;
    if (stakeArray.includes(stakeId)) {
      stakeArray.splice(stakeArray.indexOf(stakeId), 1);
    } else {
      stakeArray.push(stakeId);
    }
    console.dir(stakeArray);
    setUnStakeSelect(stakeArray);
  };

  const getStaked = () => {
    data.forEach((item) => {
      getLevel(item.identifier);
    });
  };
  const getDataBase = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataBase(blockchain.account));
    }
  };
  const getDataStake = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataStake(blockchain.account));
    }
  };
  const changeLang = () => {
    setlang(!lang);
    let langJson = require("./data/jp.json");
    if (lang) {
      langJson = require("./data/en.json");
    }
    setlangData(langJson);
  };

  const getDataSub = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataSub(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getReload = () => {
    location.reload();
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getDataBase();
    getDataSub();
    getDataStake();
  }, [datasub.isApprovedForAll]);

  useEffect(() => {
    console.dir(statuses, stakingTime);
  }, [statuses, stakingTime]);

  useEffect(() => {
    getDataBase();
    getDataStake();
    getDataSub();
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.account !== null
    ) {
      fetchData();
    }
  }, [blockchain.account]);

  useEffect(() => {
    getStaked();
    getDataBase();
  }, [data]);

  return (
    <s.Screen>
      <>
        <s.Container
          flex={1}
          ai={"center"}
          style={{ padding: 24, backgroundColor: "rgba(0, 0, 0,0)" }}
        >
          <s.SpacerLarge />
          <MintTitle
            id="mint"
            style={{
              textAlign: "center",
            }}
          >
            {"STAKE"}
          </MintTitle>

          <ResponsiveWrapper flex={1} test>
            {blockchain.account === "" ||
              (blockchain.smartContract === null && (
                <s.Container flex={1} jc={"center"} ai={"center"}></s.Container>
              ))}
            <s.Container flex={2} jc={"center"} ai={"center"}>
              {blockchain.account === "" ||
              blockchain.smartContract === null ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.SpacerLarge />
                  <s.SpacerLarge />
                  <s.SpacerLarge />
                  <s.SpacerLarge />
                  <StyledButton
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect(lang));
                    }}
                    style={{ maxWidth: 300 }}
                  >
                    CONNECT
                  </StyledButton>
                  {blockchain.errorMsg !== "" ? (
                    <>
                      <s.SpacerSmall />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {blockchain.errorMsg}
                      </s.TextDescription>
                    </>
                  ) : null}
                </s.Container>
              ) : (
                <>
                  {true ? (
                    <div style={{ width: "100%" }}>
                      <>
                        {database.isOperator && (
                          <BoxList
                          // style={{
                          //   display: dispFrame === 0 ? "flex" : "none",
                          // }}
                          >
                            <Grid container direction="column">
                              <Grid item container>
                                <Grid item xs={12} sm={12}>
                                  <Grid item xs={12} sm={12}>
                                    <TitlesTitle
                                      id="Titles"
                                      style={{
                                        textAlign: "center",
                                        perspective: "500px",
                                        backgroundColor: "red",
                                        maxWidth: 300,
                                        fontSize: "2rem",
                                        color: "#fff",
                                        fontWeight: 800,
                                      }}
                                    >
                                      {"運営強制解除"}
                                    </TitlesTitle>
                                    <>
                                      <StyledInput
                                        type="tel"
                                        value={forceUnstakeNum}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          changeforceUnstakeNum(e);
                                        }}
                                      ></StyledInput>
                                      <StyledButton
                                        onClick={(e) => {
                                          e.preventDefault();
                                          forceUnstake();
                                        }}
                                      >
                                        {claimingNft
                                          ? "Busy"
                                          : `強制解除(#${forceUnstakeNum})`}
                                      </StyledButton>
                                    </>
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <TitlesTitle
                                      id="Titles"
                                      style={{
                                        textAlign: "center",
                                        perspective: "500px",
                                        backgroundColor: "red",
                                        maxWidth: 300,
                                        fontSize: "2rem",
                                        color: "#fff",
                                        fontWeight: 800,
                                      }}
                                    >
                                      {"アドレスごとの確認"}
                                    </TitlesTitle>
                                    <>
                                      <StyledInput
                                        type="tel"
                                        value={checkAddress}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          changeCheckAddress(e);
                                        }}
                                      ></StyledInput>
                                      <StyledButton
                                        onClick={(e) => {
                                          e.preventDefault();
                                          fetchDataByAddess();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : `状況確認`}
                                      </StyledButton>
                                    </>
                                  </Grid>{" "}
                                </Grid>
                              </Grid>
                            </Grid>
                          </BoxList>
                        )}

                        <BoxList
                        // style={{
                        //   display: dispFrame === 0 ? "flex" : "none",
                        // }}
                        >
                          <Grid container direction="column">
                            <Grid item container>
                              <Grid item xs={12} sm={12}>
                                <Grid item xs={12} sm={12}>
                                  <>
                                    <TitlesTitle
                                      id="Titles"
                                      style={{
                                        textAlign: "center",
                                        perspective: "500px",
                                        color: "#000",
                                      }}
                                    >
                                      {"stake your NFT."}
                                    </TitlesTitle>
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "#000",
                                        fontFamily: "futura-pt-bold",
                                      }}
                                    >
                                      YOUR ADDRESS:{" "}
                                      {formatAddress(blockchain.account)}
                                    </s.TextDescription>
                                  </>
                                </Grid>
                              </Grid>
                              {/* <Grid item xs={4} sm={4}>
                                <StyledButtonWall
                                  onClick={(e) => {
                                    e.preventDefault();
                                    selectAllStakes();
                                    // getInit();
                                  }}
                                  sx={{
                                    width: 50,
                                  }}
                                >
                                  {"Select ALL"}
                                  <br />
                                  {langData.application}
                                </StyledButtonWall>
                              </Grid>
                              <Grid item xs={6} sm={6}>
                                <StyledButtonWall
                                  onClick={(e) => {
                                    e.preventDefault();
                                    staking();
                                    // getInit();
                                  }}
                                  sx={{
                                    width: 50,
                                  }}
                                >
                                  {"STAKE"}
                                  <br />
                                  {langData.application}
                                </StyledButtonWall>
                              </Grid> */}
                              <Grid item xs={2} sm={2}>
                                {nextToken && (
                                  <StyledButtonWall onClick={() => fetchData()}>
                                    NEXT
                                  </StyledButtonWall>
                                )}
                              </Grid>

                              <Grid item xs={12} sm={12} style={{}}>
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "#000",
                                    fontFamily: "futura-pt-bold",
                                  }}
                                >
                                  {feedback}
                                </s.TextDescription>
                                <s.SpacerLarge />
                              </Grid>
                              <Grid item xs={12} sm={12} style={{}}>
                                <TitlesTitle
                                  id="Titles"
                                  style={{
                                    textAlign: "center",
                                    perspective: "500px",
                                    color: "#000",
                                  }}
                                >
                                  {/* {"＜Selected＞"} */}
                                </TitlesTitle>
                                {stakeSelect.length > 0 ? (
                                  <>{stakeSelect.join(",") + " - "}</>
                                ) : (
                                  <></>
                                )}
                              </Grid>

                              {loading ? (
                                <p>Loading...</p>
                              ) : (
                                <>
                                  {" "}
                                  {data?.map((item) => (
                                    <>
                                      <Grid
                                        item
                                        xs={6}
                                        sm={2}
                                        key={item.identifier}
                                        style={{
                                          perspective: "500px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            width: 1 / 1.25,
                                            height: 1 / 1,
                                            mx: "auto",
                                            display: "block",
                                            backgroundColor: "none",
                                            transformStyle: "preserve-3d",
                                            opacity: "1",
                                            animationDelay: "2s",
                                            "&:hover": {
                                              backgroundColor: "white",
                                              transition: "400ms",
                                              // opacity: [0.9, 0.8, 0.7],
                                            },
                                          }}
                                        >
                                          <LazyAndPreloadImage
                                            alt={"logo"}
                                            src={item.image_url}
                                            selected={
                                              stakeSelect.includes(
                                                item.identifier
                                              ) == false
                                            }
                                          />
                                          <>
                                            {stakeSelect.includes(
                                              item.identifier
                                            ) ? (
                                              <>
                                                <StyledTitlesCover
                                                  alt={"logo"}
                                                  src={
                                                    "/config/images/choice.png"
                                                  }
                                                />
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                          <StyledTitlesName
                                            style={{
                                              display: "flex",
                                              gap: 3,
                                              color: "#333",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            #{item.identifier}
                                            {/* <button
                                              style={{
                                                border: "solid #fff 1px",
                                                margin: 3,
                                                padding: "0 5px",
                                                height: "min-content",
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                getLevel(item.identifier);
                                              }}
                                            >
                                              check
                                            </button>
                                            <button
                                              style={{
                                                border: "solid #fff 1px",
                                                margin: 3,
                                                padding: "0 5px",
                                                height: "min-content",
                                              }}
                                            >
                                              select
                                            </button> */}
                                            {loadingLevel[item.identifier] &&
                                              "Loading..."}
                                            {typeof levels[item.identifier] ===
                                              undefined && (
                                              <StyledButton
                                                style={{
                                                  border: "solid #fff 1px",
                                                  margin: 3,
                                                  padding: "0 8px",
                                                  height: "min-content",
                                                  backgroundColor: "gray",
                                                  borderRadius: 0,
                                                  fontSize: "1rem",
                                                  color: "#fff",
                                                }}
                                              >
                                                Loading
                                              </StyledButton>
                                            )}
                                            {levels[item.identifier] === 1 && (
                                              <StyledButton
                                                style={{
                                                  border: "solid #fff 1px",
                                                  margin: 3,
                                                  padding: "0 8px",
                                                  height: "min-content",
                                                  backgroundColor: "red",
                                                  borderRadius: 0,
                                                  fontSize:
                                                    window.innerWidth <= 600
                                                      ? "0.8rem"
                                                      : "1rem", // 画面幅に応じてフォントサイズを変更

                                                  color: "#fff",
                                                }}
                                              >
                                                {loadingLevel[item.identifier]
                                                  ? "..."
                                                  : "STAKED"}
                                              </StyledButton>
                                            )}
                                            {levels[item.identifier] === 0 && (
                                              <StyledButton
                                                style={{
                                                  border: "solid #fff 1px",
                                                  margin: 3,
                                                  padding: "0 8px",
                                                  height: "min-content",
                                                  backgroundColor: "green",
                                                  borderRadius: 0,
                                                  fontSize:
                                                    window.innerWidth <= 600
                                                      ? "0.8rem"
                                                      : "1rem", // 画面幅に応じてフォントサイズを変更

                                                  color: "#fff",
                                                }}
                                              >
                                                {loadingLevel[item.identifier]
                                                  ? "..."
                                                  : "NOT ON STAKE"}
                                              </StyledButton>
                                            )}
                                          </StyledTitlesName>
                                          <StyledTitlesName>
                                            {levels[item.identifier] === 1 && (
                                              <StyledButton
                                                style={{
                                                  border: "solid #fff 1px",
                                                  margin: 3,
                                                  padding: "2px 12px",
                                                  height: "min-content",
                                                  backgroundColor: "purple",
                                                  borderRadius: 10,
                                                  color: "#fff",
                                                  cursor: "pointer",
                                                  fontSize:
                                                    window.innerWidth <= 600
                                                      ? "0.8rem"
                                                      : "1.2rem", // 画面幅に応じてフォントサイズを変更

                                                  width: "90%",
                                                  "&:hover": {
                                                    transition: "400ms",
                                                    opacity: [0.9, 0.8, 0.7],
                                                  },
                                                }}
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  unstakingSolo(
                                                    item.identifier
                                                  );
                                                }}
                                              >
                                                {loadingLevel[item.identifier]
                                                  ? "..."
                                                  : "UNSTAKE"}
                                              </StyledButton>
                                            )}
                                            {levels[item.identifier] === 0 && (
                                              <StyledButton
                                                style={{
                                                  border: "solid #fff 1px",
                                                  margin: 3,
                                                  padding: "2px 12px",
                                                  height: "min-content",
                                                  backgroundColor: "blue",
                                                  borderRadius: 10,
                                                  color: "#fff",
                                                  fontSize:
                                                    window.innerWidth <= 600
                                                      ? "0.8rem"
                                                      : "1.2rem", // 画面幅に応じてフォントサイズを変更

                                                  cursor: "pointer",
                                                  width: "90%",
                                                  "&:hover": {
                                                    transition: "400ms",
                                                    opacity: [0.9, 0.8, 0.7],
                                                  },
                                                }}
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  stakingSole(item.identifier);
                                                }}
                                              >
                                                {loadingLevel[item.identifier]
                                                  ? "..."
                                                  : "STAKE"}
                                              </StyledButton>
                                            )}
                                          </StyledTitlesName>
                                          <StyledTitlesName
                                            style={{
                                              display: "flex",
                                              gap: 3,
                                              color: "#333",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            {levelsSt[item.identifier] &&
                                              `on earn:${
                                                levelsSt[item.identifier]
                                              }Pt.`}
                                            <br />
                                            {levelsAcc[item.identifier] &&
                                              `HAVE:${
                                                levelsAcc[item.identifier]
                                              }Pt.`}
                                          </StyledTitlesName>
                                        </Box>
                                      </Grid>
                                    </>
                                  ))}
                                </>
                              )}
                            </Grid>
                          </Grid>
                          <s.SpacerLarge />
                        </BoxList>
                      </>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <s.SpacerMedium />
            </s.Container>
            <s.SpacerLarge />
            {blockchain.account === "" ||
              (blockchain.smartContract === null && (
                <s.Container flex={1} jc={"center"} ai={"center"}></s.Container>
              ))}
          </ResponsiveWrapper>
        </s.Container>

        <Footer config={CONFIG} />
      </>
    </s.Screen>
  );
}

export default Home;
