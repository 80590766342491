// log
import store from "../store";

const fetchDataBaseRequest = () => {
  return {
    type: "CHECK_DATABASE_REQUEST",
  };
};

const fetchDataBaseSuccess = (payload) => {
  return {
    type: "CHECK_DATABASE_SUCCESS",
    payload: payload,
  };
};

const fetchDataBaseFailed = (payload) => {
  return {
    type: "CHECK_DATABASE_FAILED",
    payload: payload,
  };
};

export const fetchDataBase = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataBaseRequest());
    try {
      let isOperator = await store
        .getState()
        .blockchain.stakeSmartContract.methods.isOperator(account)
        .call();

      dispatch(
        fetchDataBaseSuccess({
          isOperator,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataBaseFailed("Could not load data from contract."));
    }
  };
};
