import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap"); */
  @import url("https://fonts.googleapis.com/css2?family=Pangolin&display=swap");
  @import url("https://fonts.googleapis.com/earlyaccess/hannari.css&display=swap");
  font-family: "futura-pt-bold", sans-serif;
  /* font-family: "Hannari"; */
  background-color: rbga(0, 0, 0, 0);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;

  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

export const Visible = styled.span`
  display: block;
`;
export const NotVisible = styled.span`
  display: none;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing space between components
export const SpacerLargeSpan = styled.span`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font: bold 17px "Fira Sans", serif;
  font-weight: 500;
  line-height: 1.6;
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font: bold 15px "Fira Sans", serif;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font: bold 12px "Fira Sans", serif;
  line-height: 1.6;
`;

export const MemberName = styled.p`
  color: var(--primary-text);
  font-size: 12px;
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;
